<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">证书管理</a>
          <i>></i>
          <a href="javascript:;" @click="handleBack">制证任务管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">学员管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start;">
          <div class="searchbox" style="flex-wrap:wrap">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 9em;">姓名:</span>
              <el-input v-model="searchForm.userName" clearable size="small" placeholder="请输入姓名"></el-input>
            </div>
            <div title="身份证号" class="searchboxItem ci-full">
              <span class="itemLabel">身份证号:</span>
              <el-input v-model="searchForm.idcard" clearable size="small" placeholder="请输入身份证号"></el-input>
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel">手机号:</span>
              <el-input v-model="searchForm.mobile" clearable size="small" placeholder="请输入手机号"></el-input>
            </div>
            <div title="招生渠道" class="searchboxItem ci-full">
              <span class="itemLabel">招生老师:</span>
              <el-select size="small" clearable="" v-model="searchForm.channelId" placeholder="请选择">
                <el-option v-for="item in channelsList" :key="item.channelId" :label="item.contactUser"
                  :value="item.channelId">
                </el-option>
              </el-select>
            </div>
            <div title="有无证件照片" class="searchboxItem ci-full">
              <span class="itemLabel">有无证件照片:</span>
              <el-select size="small" clearable="" v-model="searchForm.havePhoto" placeholder="请选择">
                <el-option v-for="item in havePhotos" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="flex-wrap:wrap">
            <div title="是否已生成证书" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 9em;">是否已生成证书:</span>
              <el-select size="small" v-model="searchForm.haveCert" placeholder="请选择是否已生成证书" clearable>
                <el-option :key="true" label="是" :value="true"></el-option>
                <el-option :key="false" label="否" :value="false"></el-option>
              </el-select>
            </div>
            <div title="证书有效期" class="searchboxItem">
              <span class="itemLabel" style="min-width: 8em;">证书有效期:</span>
              <el-date-picker clearable size="small" v-model="searchForm.validityDate" type="daterange"
                range-separator="至" start-placeholder="起始日期" end-placeholder="结束日期"
                value-format="yyyy-MM-dd"></el-date-picker>
            </div>
            <div title="按钮" class="searchboxItem ci-full">
              <el-button class="bgc-bv" style="margin: 0 10px" round @click="getData()">查询</el-button>
            </div>
            <div title="按钮" class="searchboxItem ci-full">
              <el-button class="bgc-bv" style="margin: 0 10px" round
                @click="Setexamination()">选择学员</el-button>
            </div>
            <!--
              <div title="按钮" class="searchboxItem ci-full">
                  <el-button
                          class="bgc-bv"
                          style="margin: 0 10px"
                          round
                          @click="importScore()"
                  >导入分数</el-button
                  >
              </div>
              -->
            <div title="按钮" class="searchboxItem ci-full">
              <el-button class="bgc-bv" style="margin: 0 10px" round
                @click="importStudentInfo()">导入学员</el-button>
            </div>
            <div title="按钮" class="searchboxItem ci-full">
              <el-button class="bgc-bv" style="margin: 0 10px" round
                @click="importStudentPic()">导入学员证件照片</el-button>
            </div>
            <div title="按钮" class="searchboxItem ci-full">
              <el-button class="bgc-bv" style="margin: 0 10px" round @click="handleDownload()">下载证书</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div style="color:red">注意：认定结果为合格的可以查看和下载证书，请在查看前导入认定结果。</div>
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="100px" />
              <el-table-column label="姓名" align="center" prop="userName" show-overflow-tooltip min-width="120px" />
              <el-table-column label="身份证号" align="center" prop="idcard" show-overflow-tooltip min-width="160px" />
              <el-table-column label="手机号" align="center" prop="mobile" show-overflow-tooltip min-width="120px" />
              <el-table-column label="职业名称" align="center" prop="occupationName" show-overflow-tooltip
                min-width="200px" />
              <el-table-column label="工种" align="center" prop="jobType" show-overflow-tooltip min-width="120px" />
              <el-table-column label="培训等级" align="center" prop="levelCode" show-overflow-tooltip min-width="120px">
                <template slot-scope="scope">{{ $setDictionary("ET_TRAIN_LEVEL", scope.row.levelCode) }}</template>
              </el-table-column>
              <el-table-column label="理论成绩" align="center" prop="theoryGrade" show-overflow-tooltip min-width="120px" />
              <el-table-column label="技能成绩" align="center" prop="skillGrade" show-overflow-tooltip min-width="120px" />
              <el-table-column label="综合成绩" align="center" prop="synthesisGrade" show-overflow-tooltip
                min-width="120px" />
              <el-table-column label="认定结果" align="center" prop="identifiedResult" show-overflow-tooltip
                min-width="120px" />
              <el-table-column label="招生老师" align="center" prop="contactUser" show-overflow-tooltip min-width="120px" />
              <el-table-column label="证书编号" align="center" show-overflow-tooltip min-width="190px">
                <template slot-scope="scope">{{ scope.row.candidateNo ? scope.row.candidateNo : '--' }}</template>
              </el-table-column>
              <el-table-column label="发证日期" align="center" prop="issueDate" show-overflow-tooltip min-width="100px" />
              <el-table-column label="有效期至" align="center" prop="validityDate" show-overflow-tooltip min-width="100px" />
              <el-table-column label="有无证件照片" align="center" prop="headPath" show-overflow-tooltip min-width="100px">
              </el-table-column>
              <el-table-column label="操作" align="center" width="120px" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button type="text" style="padding: 0px 5px" size="mini"
                    @click="handleDel(scope.row.taskUserId)">删除</el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini" :disabled="'合格' !== scope.row.identifiedResult"
                    @click="handleSee(scope.row)">查看证书</el-button>
                </div>
              </el-table-column>

              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />

        <SelectStudentsCertificateIssuance ref="SelectStudentsCertificateIssuance" @getData="getData" />
      </div>
    </div>
    <el-dialog title="预览" :visible.sync="dialogCert" width="80%" top="2%" center>
      <div id="pdf-cert" style="height: 600px"></div>
    </el-dialog>

    <el-dialog title="导入分数" :visible.sync="centerDialogVisible" v-if="centerDialogVisible" width="50%" center
      :before-close="doCloseload" class="exportdialog">
      <div class="flexdcc">
        <div class="export-box">
          <h1>
            1
            <span>下载并填写导入模板</span>
          </h1>
          <div class="df">
            <span style="
                margin-top: 20px;
                margin-right: 20px;
                color: #409eff;
                cursor: pointer;
              " @click="downloadExcelgs(templatePath)">
              制证学员成绩导入模板.xls
            </span>
          </div>
        </div>
        <div class="export-box">
          <h1>
            2
            <span>导入编写好的Excel文档</span>
          </h1>
          <div class="flexdc">
            <div class="df" style="margin: 20px 0 10px">
              <el-upload class="upload-demo upload-workers" :action="actionUrl" :on-error="handleError"
                :on-success="handleSuccess" :on-change="uploadChange" :show-file-list="false" :auto-upload="false">
                <el-button class="bgc-bv" style="font-size: 12px; width: 80%">浏览</el-button>
              </el-upload>
              <p v-if="fileName" style="margin-top: 10px; margin-left: 10px">
                当前选择文件：
                <span style="color: #f46173">{{ fileName }}</span>
              </p>
              <p v-else style="margin-top: 10px; margin-left: 10px">
                未选择文件
              </p>
            </div>
            <div>
              <el-button class="bgc-bv" size="mini" style="margin-top: 10px; height: 35px" :disabled="doExportInDisabled"
                @click="doExport">开始导入</el-button>
            </div>
            <div style="margin-top: 10px" v-if="progressVal > 0">
              <el-progress :text-inside="true" :stroke-width="20" :percentage="progressVal"></el-progress>
            </div>
          </div>
        </div>
        <div class="export-box">
          <h1>
            3
            <span>导入结果</span>
          </h1>
          <div class="df studentNum">
            <span>分数总数量:{{ totalNum }}个;</span>
            <span>成功:{{ correctNum }}个;</span>
            <span>失败:{{ errorNum }}个;</span>
          </div>
          <div>
            <el-button class="bgc-bv" style="margin-top: 15px; height: 35px" size="mini" :disabled="errorNum == '0'"
              @click="doExportError">导出错误数据</el-button>
          </div>
          <div style="margin-top: 15px; color: #dd1d35" v-if="errorNum != 0">
            有导入失败分数，请导出错误数据，将导入失败分数调整后重新上传
          </div>
        </div>
      </div>
    </el-dialog>
    <!--鉴定机构通用导入-->
    <!-- businessType  导入业务类型标识 [ BUSINESS_TYPE ]-->
    <!-- importType  导入类型 学员-->
    <!-- unit  单位 学员-人-->
    <!--fileType  EXCEL  ZIP-->
    <accreditationAgencyImport ref="accreditationAgencyImport" @eventBus="accreditationAgencyImportBack"
      businessType="CREDENTIAL_MAKING_USER_IMPORT" importType="学员" unit="人" fileType="EXCEL" />
    <accreditationAgencyImport ref="accreditationAgencyImport2" @eventBus="accreditationAgencyImportBack"
      businessType="CREDENTIAL_MAKING_PICTURE_IMPORT" importType="证件照片" desc="1.上传后缀为.zip格式的压缩包文件，将证件照片直接添加到压缩包内，不要先放在文件夹再添加到压缩包内；
2.上传压缩包最大限制100M，如文件超过100M，请分成多个压缩包分批导入；
3.压缩包内文件为图片格式，以身份证号命名，例如：6101011900001010151.jpg" unit="个" fileType="ZIP" />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive, validatePhone, validateIdCard } from "@/utils/common";
import SelectStudentsCertificateIssuance from "@/views/certificateManage/popup/SelectStudents";
import pdf from "pdfobject";
import accreditationAgencyImport from "@/components/accreditationAgencyImport.vue";
import { mapGetters } from "vuex";
export default {
  name: "chooseStudentList",
  components: {
    accreditationAgencyImport,
    Empty,
    PageNum,
    SelectStudentsCertificateIssuance,
  },
  mixins: [List],
  data() {
    return {
      taskId: '',
      templateCode: '',
      certNoRole: "", // 
      certificationNoNum: 0,
      searchForm: {
        userName: "",      //姓名
        mobile: "",        //手机号
        idcard: "",        //身份证
        haveCert: "",      //有无证书
        channelId: "",     //招生老师（渠道）
        validityDate: '',  //有效期（截止）
        havePhoto: "",
      },
      dialogCert: false,
      // 招生渠道数据
      channelsList: [],
      havePhotos: [{
        value: true,
        label: '是'
      }, {
        value: false,
        label: '否'
      }],
      // 导入
      centerDialogVisible: false,
      totalNumlb: 0,
      correctNumlb: 0,
      errorNumlb: 0,
      progressVal: 0,
      batchId: "",
      templatelist: "", // 模板数据
      templatePath: '',
      fileName: "",
      fileKey: "",
      excelUrl: "",
      importType: "",
      doExportInDisabled: false, //开始导入按钮禁用
    };
  },
  created() {
    this.taskId = this.$route.query.taskId;
    this.templateCode = this.$route.query.templateCode;
    this.certNoRole = this.$route.query.certNoRole;
    this.getChannels();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    // 获取招生渠道数据
    getChannels() {
      this.$post("/biz/channel/list", {}, 3000, true, 6).then(res => {
        this.channelsList = res.data || [];
        this.channelsList.unshift({
          contactUser: "自招",
          channelId: -1000,
        });
      },).catch(e => {
        console.error("获取招生渠道数据出错", e);
      });
    },
    importStudentInfo() {
      this.$refs.accreditationAgencyImport.showPopup(this.taskId)
    },

    importStudentPic() {
      this.$refs.accreditationAgencyImport2.showPopup(this.taskId)
    },
    handleDownload() {
      const params = {
        taskId: this.taskId,
        templateCode: this.templateCode
      }
      if (this.searchForm.userName) {
        params.userName = this.searchForm.userName;
      }
      if (this.searchForm.mobile) {
        params.mobile = this.searchForm.mobile;
      }
      if (this.searchForm.idcard) {
        params.idcard = this.searchForm.idcard;
      }
      if (this.searchForm.haveCert === true || this.searchForm.haveCert === false) {
        params.haveCert = this.searchForm.haveCert;
      }
      if (this.searchForm.channelId) {
        params.channelId = this.searchForm.channelId;
      }
      if (this.searchForm.validityDate && this.searchForm.validityDate.length > 0) {
        params.validityStartDate = this.searchForm.validityDate[0]
        params.validityEndDate = this.searchForm.validityDate[1]
      }
      if ('boolean' == typeof this.searchForm.havePhoto) {
        params.havePhoto = this.searchForm.havePhoto;
      }
      this.$post('/biz/cms/task/selectNoCertNum', params, 3000, true, 6).then(resx => {
        if (this.certNoRole == '20' && resx.data.certificationNoNum > 0) {
          this.$confirm(
            "有学员没有证书编号，是否继续下载",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              this.$post('/biz/cms/task/cert/download', params, 3000, true, 6).then(res => {
                if (res.status == "0") {
                  if (res.data.length) {
                    for (let item of res.data) {
                      if (!this.downloadItems.includes(item.taskId)) {
                        this.$store.dispatch("pushDownloadItems", item.taskId);
                      } else {
                        this.$message.warning(
                          "[" + item.fileName + "]已经申请下载,请耐心等待"
                        );
                      }
                    }
                  }
                } else {
                  this.$message.error(res.message);
                }
              })
                .catch((err) => {
                  return;
                });
            })
            .catch(() => {
              return;
            });
        } else {
          this.$post('/biz/cms/task/cert/download', params, 3000, true, 6).then(res => {
            if (res.status == "0") {
              if (res.data.length) {
                for (let item of res.data) {
                  if (!this.downloadItems.includes(item.taskId)) {
                    this.$store.dispatch("pushDownloadItems", item.taskId);
                  } else {
                    this.$message.warning(
                      "[" + item.fileName + "]已经申请下载,请耐心等待"
                    );
                  }
                }
              }
            } else {
              this.$message.error(res.message);
            }
          })
            .catch((err) => {
              return;
            });
        }

      })
        .catch((err) => {
          return;
        });
    },
    accreditationAgencyImportBack() {
      this.getData(-1)
    },
    handleBack() {
      // setTimeout(() => {
      this.$router.push({
        path: "/web/certificateManage/voucherPreparationTaskList",
        query: {
          refresh: true,
        },
      });
      // }, 3000);
    },
    /* 获取列表数据 */
    getData(pageNum = 1) {
      // console.log(this.$route.query.taskId)
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        taskId: this.$route.query.taskId
      };
      if (this.searchForm.userName) {
        params.userName = this.searchForm.userName;
      }
      if (this.searchForm.mobile) {
        params.mobile = this.searchForm.mobile;
      }
      if (this.searchForm.idcard) {
        params.idcard = this.searchForm.idcard;
      }
      if (this.searchForm.haveCert === true || this.searchForm.haveCert === false) {
        params.haveCert = this.searchForm.haveCert;
      }
      if (this.searchForm.channelId) {
        params.channelId = this.searchForm.channelId;
      }
      if (this.searchForm.validityDate && this.searchForm.validityDate.length > 0) {
        params.validityStartDate = this.searchForm.validityDate[0]
        params.validityEndDate = this.searchForm.validityDate[1]
      }
      if ('boolean' == typeof this.searchForm.havePhoto) {
        params.havePhoto = this.searchForm.havePhoto;
      }
      console.log(params)
      this.doFetch({
        url: "/biz/cms/task/user/pageList",
        params,
        pageNum,
      }, true, 6);
    },
    // 选择学员弹窗打开
    Setexamination() {
      // console.log('userSource',this.$route.query.userSource)
      this.$refs.SelectStudentsCertificateIssuance.showPopUp(this.$route.query.taskId, this.$route.query.userSource);
    },
    // 删除
    handleDel(id) {
      this.doDel({
        title: "删除",
        url: "/biz/cms/task/user/delete",
        msg: "是否确认删除该学员",
        ps: {
          type: "post",
          data: { taskUserId: id },
        },
      }, true, 6);
    },
    // 查看证书
    handleSee(row) {
      if (this.certNoRole == '20' && row.candidateNo =='--') {
        this.$confirm(
          "学员没有证书编号,是否继续查看",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.$post('/biz/cms/task/user/gain/cert', { taskUserId: row.taskUserId, taskId: this.$route.query.taskId }, 3000, true, 6).then(ret => {
              if (ret.status == 0) {
                this.dialogCert = true;
                this.$nextTick(() => {
                  pdf.embed(ret.data, "#pdf-cert");
                });
              }
            })
              .catch((err) => {
                return;
              });
          })
          .catch(() => {
            return;
          });
      } else {
        this.$post('/biz/cms/task/user/gain/cert', { taskUserId: row.taskUserId, taskId: this.$route.query.taskId }, 3000, true, 6).then(ret => {
          if (ret.status == 0) {
            this.dialogCert = true;
            this.$nextTick(() => {
              pdf.embed(ret.data, "#pdf-cert");
            });
          }
        })
          .catch((err) => {
            return;
          });
      }
    },
    /**
     * 返回
     */
    dohandleOk() {
      setTimeout(() => {
        this.$router.push({
          path: "/web/certificateManage/voucherPreparationTaskList",
          query: {
            refresh: true
          }
        });
      }, 300);
    },

    /* 导入 */
    importScore() {
      this.totalNum = "0";
      this.errorNum = "0";
      this.correctNum = "0";
      this.batchId = "";
      this.getTemplate();

    },
    downloadExcelgs(templatePath) {
      /**
       * 下载模板
       * @param param 参数
       */
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = templatePath;
      let templateNames = templatePath.slice(8);
      console.log(templateNames);
      link.setAttribute("download", templateNames);
      document.body.appendChild(link);
      link.click();
    },
    // 导入学员
    uploadChange(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 3) {
        this.$message.error("文件大小不能超过3M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          // console.log(result);
          this.fileKey = result.data.fileKey;
          // this.fileName = result.data.fileKey;
        })
        .catch((err) => {
          return;
        });
    },
    doExport() {
      if (this.fileKey == "") {
        this.$message({
          message: "请选择文件",
          type: "warning",
        });
      } else {
        // this.$confirm("是否将此表中学员绑定培训任务?", "提示", {
        //   confirmButtonText: "确定",
        //   confirmButtonClass: "confirmButtonClass",
        //   cancelButtonText: "取消",
        //   type: "warning",
        // })
        //     .then(() => {
        this.doExportInDisabled = true;
        if (this.fileKey) {
          const parmar = {
            taskId: this.$route.query.taskId,
            excelUrl: this.fileKey,
          };
          this.$post("/biz/cms/task/user/importUserScore", parmar, 5000, true, 6)
            .then((res) => {
              if (res.status == 0) {
                if (res.status == 0) {
                  this.doProgress(res.message, this.$route.query.taskId);
                }
              }
            })
            .catch(() => {
              return;
            });
        } else {
          this.$message({
            message: "请选择文件",
            type: "warning",
          });
          this.doExportInDisabled = false
        }
        // })
        // .catch(() => {
        //   return;
        // });
      }
    },
    //获取导入进度
    /* /biz/projectUser/importProgress */
    doProgress(batchId, taskId) {
      console.log(batchId, taskId)
      this.$post(
        "/biz/cms/task/user/importProgress",
        { batchId, taskId },
        5000, true, 6
      ).then((ret) => {
        if (!ret.data.status) {
          this.progressVal = ret.data.progress;
          setTimeout(() => {
            this.doProgress(batchId, taskId);
          }, 2000);
        } else {
          if (ret.data.message) {
            this.$message({
              type: "error",
              message: ret.data.message,
            });
          } else {
            this.totalNum = ret.data.totalNum;
            this.errorNum = ret.data.errorNum;
            this.correctNum = ret.data.correctNum;
            this.importType = ret.data.importType;
            this.batchId = ret.data.batchId;
            this.progressVal = ret.data.progress;
            this.$message({
              type: "success",
              message: "导入成功",
            });
            this.doExportInDisabled = false;
          }
        }
      });
    },
    //导出错误数据
    doExportError() {
      // console.log(this.batchId);
      if (this.batchId == "" || this.fileKey == "") {
        this.$message({
          message: "还没有导入数据，请先导入文档",
          type: "warning",
        });
      } else {
        this.$post(
          "/biz/cms/task/user/exportError",
          { batchId: this.batchId, projectId: this.projectId },
          5000, true, 6
        ).then((res) => {
          if (res.status == 0) {
            window.open(res.data);
          }
        });
      }
    },
    doCloseload() {
      this.centerDialogVisible = false;
      this.newvalues = [];
      this.fileKey = "";
      this.fileName = "";
      this.progressVal = 0;
      this.doExportInDisabled = false;
      this.getData();
    },
    getTemplate() {
      this.$post("/biz/cms/task/user/gain/exportScoreTemplate", {
        taskId: this.$route.query.taskId
      }, 3000, true, 6).then((ret) => {
        console.log(ret.data)
        if (ret.status == '0') {
          this.templatePath = ret.data ? ret.data : '';
          this.centerDialogVisible = true;
        }

      });
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {

  },
};
</script>
<style lang="less" scoped>
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;

    &:before {
      content: "\e6df" !important;
    }
  }

  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;

    .el-tabs__content {
      flex: 1;
    }
  }
}

.Classitem {
  .el-form-item__content {
    display: flex;
  }
}

.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  background-color: transparent;
}

.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}

.btnBox {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.exportdialog {
  .el-dialog__title {
    font-size: 16px;
  }

  .export-box {
    width: 100%;
    margin-bottom: 20px;

    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;

      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }

    >div {
      padding-left: 30px;
    }

    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
}

.docsDialog {
  .el-form-item {
    margin-bottom: 0.5rem;
  }
}

.studentNum {
  margin: 20px 0 10px;

  span {
    margin: 0 10px;
  }
}

.exportdialog {
  .upload-workers {
    height: 30px;

    .el-upload {
      height: 30px !important;
      width: 97px;
      border: none !important;

      .el-button {
        padding: 5px 0;
      }
    }
  }
}

/deep/.el-dialog__body .flexdcc .el-upload {
  min-width: 100px;
  width: 100px;
  height: 30px;
  border: 0;
}

.searchboxItem {
  margin-right: 0;
  margin-bottom: 15px;
}
</style>
