<template>
  <el-dialog
    :visible.sync="dialogVisible"
    title="选择学员"
    top="1%"
    width="80%"
    :center="true"
    :before-close="tableBack"
    :close-on-click-modal="false"
  >
    <div class="CourseTableBox">
      <section class="CourseTable">
        <div class="operationControl">
          <div class="searchbox" style="padding: 0">
              <div title="考务/招生任务名称" class="searchboxItem ci-full">
<!--                //01 考务  02 报名-->
                <span class="itemLabel" style="min-width: 9em;">{{userSource == '01'?'考务':userSource == '02'?'招生任务':'--'}}名称:</span>
                <el-input
                    v-model="searchForm.sourceTargetName"
                    clearable
                    size="small"
                    :placeholder="userSource == '01'?'请输入考务名称':userSource == '02'?'请输入招生任务名称':'--'"
                ></el-input>
              </div>
              <div title="姓名" class="searchboxItem ci-full">
                <span class="itemLabel">姓名:</span>
                <el-input
                    v-model="searchForm.userName"
                    clearable
                    size="small"
                    placeholder="请输入姓名"
                ></el-input>
              </div>
              <div title="手机号" class="searchboxItem ci-full">
                <span class="itemLabel">手机号:</span>
                <el-input
                    v-model="searchForm.mobile"
                    clearable
                    size="small"
                    placeholder="请输入手机号"
                ></el-input>
              </div>
<!--            <div title="培训名称" class="searchboxItem ci-full">-->
<!--              <span class="itemLabel">培训名称:</span>-->
<!--              <el-select-->
<!--                  v-model="searchForm.projectId"-->
<!--                  filterable-->
<!--                  clearable-->
<!--                  remote-->
<!--                  size="small"-->
<!--                  placeholder="请至少输入两字搜索"-->
<!--                  :remote-method="getProjectList"-->
<!--              >-->
<!--                <el-option-->
<!--                    v-for="item in projectList"-->
<!--                    :key="item.projectId"-->
<!--                    :label="item.sourceTargetName"-->
<!--                    :value="item.projectId"-->
<!--                >-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </div>-->
            <div>
              <el-button class="bgc-bv" @click="search()">查询</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <p class="tipsText">{{tipsShow?tipsText:''}}</p>
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              stripe
              :header-cell-style="tableHeader"
              @selection-change="handleSelectionChange"
              :row-key="(row) => row.sourceId"
              @cell-mouse-enter="handleMouseEnter"
              @cell-mouse-leave="handleMouseLeave"
            >
              <el-table-column
                  type="selection"
                  :reserve-selection="true"
                  :selectable="checkSelectable"
                  width="55">
              </el-table-column>
              <el-table-column
                label="姓名"
                align="center"
                show-overflow-tooltip
                prop="userName"
                minWidth="100"
              />
              <el-table-column
                  label="身份证号"
                  align="center"
                  show-overflow-tooltip
                  prop="idcard"
                  minWidth="160"
              />
              <el-table-column
                  label="手机号"
                  align="center"
                  show-overflow-tooltip
                  prop="mobile"
                  minWidth="100"
              />
              <el-table-column
                  :label="userSource == '01'?'考务':userSource == '02'?'招生任务名称':'--'"
                  align="center"
                  show-overflow-tooltip
                  prop="sourceTargetName"
                  minWidth="100"
              />
              <el-table-column
                  label="培训工种"
                  align="center"
                  show-overflow-tooltip
                  prop="occupationName"
                  minWidth="100"
              />
              <el-table-column
                  label="培训等级"
                  align="center"
                  show-overflow-tooltip
                  prop="levelCode"
                  minWidth="100"
              >
                <template slot-scope="scope">{{$setDictionary("ET_TRAIN_LEVEL", scope.row.levelCode)}}</template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </section>
      
    </div>
    <span class="dialog-footer">
      <div class="dialogBtn">
        <el-button @click="tableBack">取消</el-button>
        <el-button class="bgc-bv" @click="doOk">确定</el-button>
      </div>
    </span>
<!--    <PaperShow ref="PaperShow" :modal="false" />-->
  </el-dialog>
</template>
<script>
import Empty from "@/components/Empty4.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
// import PaperShow from "@/views/resourse/popup/PaperShow";
// import tree from "@/components/treePopup";
import { mapGetters } from "vuex";

export default {
  name: "SelectStudents2",
  components: {
    Empty,
    PageNum,
    // PaperShow,
    // tree,
  },
  mixins: [List],
  doNotInit: true,
  data() {
    return {
      dialogVisible: false,
      multipleSelection: [],
      taskId: '',
      userSource: '',//01 考务  02 报名
      searchForm: {
        sourceTargetName: '',
        userName: '',
        mobile: '',
      },
      tipsShow: false,
      tipsText: '学员没有实名，请联系学员做认证。',
      // projectId: '',
      // ctProjectId: '',
      // EnrollmentList: [],
      selectionKeys: [],


      // 培训下拉数据
      // projectList:[],

    };
  },
  created() {

  },
  computed: {

  },
  methods: {
    // 行hover时检查是否实名认证
    handleMouseEnter(row){
      if(!row.authentication){
        this.tipsShow = true
        this.tipsText = '学员没有实名，请联系学员做认证。'
      } else if (!row.haveHeadPath) {
        this.tipsShow = true
        this.tipsText = '学员没有免冠照片，请联系学员上传照片。'
      }
    },
    // 行Leave时检查是否实名认证
    handleMouseLeave(){
      this.tipsShow = false
    },
    // 置灰条件
    checkSelectable(row, index) {
      return row.authentication === true && row.haveHeadPath === true // 你的判断条件，条件成立则复选框能选，否则置灰禁用
    },
    // 培训搜索方法
    // getProjectList(query){
    //   if(query.trim().length<2){
    //     return;
    //   }
    //   this.$post("/biz/ct/project/ctProjectListNoPage",{
    //     sourceTargetName:query
    //   }).then(res=>{
    //     this.projectList=res.data||[];
    //   });
    // },
    // 多选选中
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(this.multipleSelection)
    },
    // 招生信息
    // getEnrollmentList(query) {
    //   if (query.trim().length >= 2) {
    //     this.$post("/biz/ct/project/ctProjectListNoPage", { sourceTargetName: query })
    //         .then((res) => {
    //           if (res.status == 0) {
    //             this.EnrollmentList = res.data || [];
    //           }
    //         })
    //         .catch(() => {
    //           return;
    //         });
    //   } else {
    //     this.EnrollmentList = [];
    //   }
    // },
    // tinit() {
    //   // this.getCourseOriginList();
    //   this.init();
    //   this.getTableHeight();
    // },
    search(){
      // if( !this.searchForm.sourceTargetName ){
      //   this.$message.error("请输入培训名称查询！");
      // }else{
        // this.searchForm.sourceTargetName = this.projectList.filter(item => item.projectId == this.searchForm.projectId)[0].sourceTargetName
        this.$refs.multipleTable.clearSelection()
        // console.log(this.multipleSelection)
        this.getData()
      // }
    },
    async getData(pageNum = 1) {

      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        taskId: this.taskId,
        // sourceTargetName: this.searchForm.sourceTargetName
      };
      if(this.searchForm.sourceTargetName){
        params.sourceTargetName = this.searchForm.sourceTargetName
      }
      if(this.searchForm.userName){
        params.userName = this.searchForm.userName
      }
      if(this.searchForm.mobile){
        params.mobile = this.searchForm.mobile
      }
      // console.log(params)
      return this.doFetch({
        url: "/biz/cms/task/user/selectChooseUserList",
        params,
        pageNum,
      },true,6);
      // setTimeout(()=> {
      //   this.selectionKeys.forEach(key => {
      //     this.tableData.forEach(row => {
      //       if (row.userId== key.userId) {
      //         this.$refs.multipleTable.toggleRowSelection(row, true);
      //       }
      //     })
      //   })
      // },1000)
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = 660 - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3.5) * 16;
      if (opDom) {
        tHeight -= 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    doOk() {
      let _this = this
      if(!this.multipleSelection.length){
        this.$message.error("请至少选择一个学员！");
        return
      }else{
        let sourceIds = this.multipleSelection.map(item=>item.sourceId)
        let params = {
          taskId: this.taskId,
          sourceIds: sourceIds
        }
        // console.log(params)
        this.$post("/biz/cms/task/user/insert", params,3000,true,6)
            .then((res) => {
              if (res.status == 0) {
                _this.$message({
                  message: "成功添加",
                  type: "success",
                });
                _this.tableBack()
              } else {
                _this.$message({
                  message: ret.message,
                  type: "error",
                });
              }
            })
            .catch(() => {
              return;
            });
      }
    },
    tableBack() {
      this.dialogVisible = false;
      // this.ctProjectId = ''
      this.tableData = []
      this.apiData = {
        total: 0,
        size: 10,
        current: 1
      }
      // this.projectList = [];
      this.selectionKeys = [];

      this.$parent.getData()

    },
    async showPopUp(taskId,userSource) {
      // console.log('userSource',userSource)
      this.taskId = taskId
      this.userSource = userSource

      this.tableData = []
      this.apiData = {
        total: 0,
        size: 10,
        current: 1
      }
      this.projectList = [];
      this.selectionKeys = [];
      this.searchForm = {
        userName: '',
        mobile: '',
        sourceTargetName: ''
      }
      this.dialogVisible = true;
      await this.getData()

      this.$refs.multipleTable.clearSelection()
    },
  },
};
</script>
<style lang="less">
.tipsText{
  height: 20px;
  line-height: 20px;
  color: red;
  margin-bottom: 0.675rem;
}
.CourseTable {
  width: 100%;
  border: 1px solid #5c6be8;
  padding: 10px;
  border-radius: 0.5rem;
  .el-radio {
    .el-radio__label {
      display: none;
    }
  }
}
.testTime {
  // margin-left: 180px;
  padding-top: 20px;
  .itemLabel {
    display: inline-block;
    width: 130px;
    padding-bottom: 0.75rem;
    line-height: 2;
  }
  .testFomation {
    display: block;
    width: 300px;
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.dialogBtn {
  button {
    width: 10rem;
  }
}
</style>
